import {DEFAULT_CURRENCY_CODE, NgModule} from '@angular/core';
import {CommonModule, CurrencyPipe, DatePipe} from '@angular/common';
import {EditFormComponent} from './edit-form.component';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {FormComponent} from './form/form.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {NgxCurrencyDirective} from 'ngx-currency';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDateFormats} from '@angular/material/core';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {RouterModule} from '@angular/router';
import {IconModule} from '../icon/icon.module';
import {EffectsModule} from '@ngrx/effects';
import {MatLegacyAutocompleteModule as MatAutocompleteModule} from '@angular/material/legacy-autocomplete';
import {IbanAutocompleteOptionModule} from '../iban-autocomplete-option/iban-autocomplete-option.module';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {ZahlungEffects} from '../../store/effects/zahlung.effects';
import {BelegEffects} from '../../store/effects/beleg.effects';
import {DeletedDialogModule} from '../deleted-dialog/deleted-dialog.module';
import {MatLegacyDialogModule} from '@angular/material/legacy-dialog';


export const MY_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    EditFormComponent,
    FormComponent,
  ],
  imports: [
    RouterModule.forChild([{
      path: '',
      component: EditFormComponent,
    }]),
    EffectsModule.forFeature([
      ZahlungEffects,
      BelegEffects,
    ]),
    CommonModule,
    MatButtonModule,
    MatInputModule,
    ReactiveFormsModule,
    NgxCurrencyDirective,
    MatDatepickerModule,
    IconModule,
    MatAutocompleteModule,
    IbanAutocompleteOptionModule,
    MatTooltipModule,
    DeletedDialogModule,
    MatLegacyDialogModule,
  ],
  providers: [
    {provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR'},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    DatePipe,
    MatDatepickerModule,
    CurrencyPipe,
  ]
})
export class EditFormModule {
}
