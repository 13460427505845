import {createReducer, on} from '@ngrx/store';
import {BelegState} from '../../interfaces/beleg-state.interface';
import {BelegActions} from '../actions/beleg.actions';


export const initialBelegState: BelegState = {
  belegPreviewData: {
    belegIsLoading: false,
    closeable: true,
    highlight: [],
  },
  belegFilterDialogData: {
    isBelegFilterDialogOpen: false,
    count: 0,
    filter: {},
    pagination: {
      offset: 0,
      size: 50,
    },
    sorting: {
      sortDirs: [],
      sortParams: [],
    },
    columns: [],
  },
  belegCancelledDialogData: {
    isBelegCancelledDialogOpen: false,
  }
};

export const belegReducers = createReducer(
  initialBelegState,

  /*
   * INFO: Beleg Preview Reducers
   */

  on(
    BelegActions.loadContent,
    (state, action) => ({
      ...state,
      belegPreviewData: {
        ...state.belegPreviewData,
        tryToLoadBeleg: action.belegDto,
        belegIsLoading: true,
        previewBelegDto: undefined,
        content: undefined,
        closeable: action.closeable,
      }
    })
  ),

  on(
    BelegActions.contentLoaded,
    (state, action) => ({
      ...state,
      belegPreviewData: {
        ...state.belegPreviewData,
        previewBelegDto: action.belegDto,
        content: action.content,
        belegIsLoading: false,
        tryToLoadBeleg: undefined,
        isPdf: action.belegDto?.contentType === 'application/pdf',
      },
    })
  ),

  on(
    BelegActions.loadIframeContent,
    (state) => ({
      ...state,
      belegPreviewData: {
        ...state.belegPreviewData,
        iFramePreviewDialogBeleg: undefined,
        iFramePreviewDialogContent: undefined,
      },
    })
  ),

  on(
    BelegActions.iFrameContentLoaded,
    (state, action) => ({
      ...state,
      belegPreviewData: {
        ...state.belegPreviewData,
        iFramePreviewDialogBeleg: action.belegDto,
        iFramePreviewDialogContent: action.content,
      },
    })
  ),

  on(
    BelegActions.closeBelegPreview,
    (state) => ({
      ...state,
      belegPreviewData: {
        ...initialBelegState.belegPreviewData,
      },
    })
  ),

  on(
    BelegActions.resetBelegPreview,
    (state) => ({
      ...state,
      belegPreviewData: {
        ...state.belegPreviewData,
        isPdf: undefined,
        tryToLoadBeleg: undefined,
        content: undefined,
        previewBelegDto: undefined,
        belegIsLoading: false,
        closeable: undefined,
      },
    })
  ),

  on(
    BelegActions.highlightWords,
    (state, action) => ({
      ...state,
      belegPreviewData: {
        ...state.belegPreviewData,
        highlight: action.words,
      },
    })
  ),

  /*
   * INFO: Beleg-Filter-Dialog Reducers
   */

  on(
    BelegActions.openBelegFilterDialog,
    (state) => ({
      ...state,
      belegFilterDialogData: {
        ...state.belegFilterDialogData,
        isBelegFilterDialogOpen: true,
        filter: initialBelegState.belegFilterDialogData.filter,
        pagination: initialBelegState.belegFilterDialogData.pagination,
      },
    })
  ),

  on(
    BelegActions.closeBelegFilterDialog,
    (state) => ({
      ...state,
      belegFilterDialogData: {
        ...state.belegFilterDialogData,
        isBelegFilterDialogOpen: false,
        lastPreviewed: undefined,
        filter: initialBelegState.belegFilterDialogData.filter,
        pagination: initialBelegState.belegFilterDialogData.pagination,
      },
    })
  ),

  on(
    BelegActions.loadData,
    (state, action) => ({
      ...state,
      belegFilterDialogData: {
        ...state.belegFilterDialogData,
        data: action.data,
        count: action.count,
      },
    })
  ),

  on(
    BelegActions.setFilter,
    (state, action) => ({
      ...state,
      belegFilterDialogData: {
        ...state.belegFilterDialogData,
        filter: action.filter,
      },
    })
  ),

  on(
    BelegActions.changePagination,
    (state, action) => ({
      ...state,
      belegFilterDialogData: {
        ...state.belegFilterDialogData,
        pagination: action.pagination,
      },
    })
  ),

  on(
    BelegActions.resetPagination,
    (state) => ({
      ...state,
      belegFilterDialogData: {
        ...state.belegFilterDialogData,
        pagination: initialBelegState.belegFilterDialogData.pagination,
      },
    })
  ),

  on(
    BelegActions.changeColumns,
    (state, action) => ({
      ...state,
      belegFilterDialogData: {
        ...state.belegFilterDialogData,
        columns: action.columns,
      },
    })
  ),

  on(
    BelegActions.resetColumns,
    (state) => ({
      ...state,
      belegFilterDialogData: {
        ...state.belegFilterDialogData,
        columns: initialBelegState.belegFilterDialogData.columns,
      },
    })
  ),

  on(
    BelegActions.changeSorting,
    (state, action) => ({
      ...state,
      belegFilterDialogData: {
        ...state.belegFilterDialogData,
        sorting: action.sorting,
      },
    })
  ),

  on(
    BelegActions.resetSorting,
    (state) => ({
      ...state,
      belegFilterDialogData: {
        ...state.belegFilterDialogData,
        sorting: initialBelegState.belegFilterDialogData.sorting,
      },
    })
  ),

  on(
    BelegActions.belegToAssignSelected,
    (state, action) => ({
      ...state,
      belegFilterDialogData: {
        ...state.belegFilterDialogData,
        belegToAssignSelected: action.belegDto,
      }
    })
  ),

  /*
   * INFO: Beleg Storniert Reducers
   */

  on(
    BelegActions.openBelegCancelledDialog,
    (state, action) => ({
      ...state,
      belegCancelledDialogData: {
        isBelegCancelledDialogOpen: true,
        inhaberId: action.inhaberId,
        zahlungId: action.zahlungId,
      }
    })),

  on(
    BelegActions.closeBelegCancelledDialog,
    (state) => ({
      ...state,
      belegCancelledDialogData: {
        isBelegCancelledDialogOpen: false,
        inhaberId: undefined,
        zahlungId: undefined,
      }
    })),
);
