<!-- Headline -->
<div class="row px-2 px-md-4 m-0 mt-4">
  <div class="p-0 m-0 mt-4 mb-md-4 title">{{!angewiesen ? 'Überweisung erstellen' : 'Überweisung angewiesen'}}</div>
</div>

<div class="d-flex px-md-4 m-0 mb-5 not-editable-hint" *ngIf="isNotEditable">
  <div class="not-editable-icon-wrapper">
    <zlng-icon icon="common:not_editable" primaryStroke="black"></zlng-icon>
  </div>
  <div>
    Diese Überweisung ist nicht bearbeitbar. Sie wurde in ADNOVA+ erstellt und kann nur dort verändert werden.
  </div>
</div>

<div class="d-flex px-md-4 m-0 mb-5 not-editable-hint" *ngIf="isBelegCancelled">
  <div class="not-editable-icon-wrapper">
    <zlng-icon icon="common:beleg-cancelled" primaryStroke="black" accentStroke="red"></zlng-icon>
  </div>
  <div>
    Dieser Beleg wurde storniert. Bitte wähle, ob der Beleg neu zugeordnet oder die Zahlung gelöscht werden soll.
  </div>
</div>

<!-- Actual Form -->
<div class="container scroll-container">
  <div class="row px-2 px-md-4 m-0 mt-3 form-panel">
    <zlng-form
      #formComponent
      (highlight)="doHighlight($event)"
    ></zlng-form>
  </div>
</div>

<!-- Action Buttons -->
<div class="row p-2 p-md-4 pt-md-1 action-panel">
  <hr class="mx-4"/>
  <div class="button-wrapper">
    <div [matTooltip]="isBelegCancelled ? 'Zahlungen mit storniertem Beleg können nicht überwiesen werden.' : ''"
    >
      <button mat-flat-button
              *ngIf="!angewiesen"
              color="accent"
              [disabled]="isBelegCancelled"
              (click)="saveZahlung(true)"
      >
        <zlng-icon icon="common:arrow_right" primaryStroke="white"></zlng-icon>
        Überweisen
      </button>
    </div>
      <button mat-button
              *ngIf="!angewiesen && !isNotEditable"
              (click)="saveZahlung(false)"
              class="fw-bold"
      >Speichern und später überweisen
      </button>
  </div>
</div>
