<div class="wizard-wrapper">
  <div class="row m-0 p-0 h-100">
    <div class="col-12 col-md-7 m-0 p-0">
      <router-outlet name="left"></router-outlet>
    </div>
    <div class="col-12 col-md-5 m-0 p-0 h-100">
      <div class="d-flex justify-content-center h-100">
        <div class="row px-2 px-md-4 m-0 content-wrapper h-100 d-flex flex-column">

          <!-- LOOP of Action Buttons -->
          <div
            *ngIf="showHeader"
            class="row p-2 p-md-3 m-0 d-flex flex-row"
          >

            <!-- Collected Payments Browser -->
            <div class="d-flex w-auto p-0 m-0 payment-browser" *ngIf="finalPayments.length > 1">
              <zlng-payment-browser></zlng-payment-browser>
            </div>


            <div class="d-flex w-auto p-0 m-0 ms-auto nav-buttons">
              <ng-container *ngFor="let actionButton of actionButtons; index as i;">
                <button
                  mat-flat-button
                  routerLink="{{actionButton.href}}"
                  [queryParams]="actionButton.queryParams"
                  (click)="doLogout(actionButton.logout)"
                >
                  <zlng-icon *ngIf="actionButton.icon" [icon]="actionButton.icon" primaryFill="black"></zlng-icon>
                  {{actionButton.title}}
                </button>

                <div *ngIf="i+1 < actionButtons.length" class="divider"></div>
              </ng-container>
            </div>

          </div>

          <!-- Horizontal line -->
          <div
            *ngIf="showHeader"
            class="row p-0 pe-md-2 pe-md-4 m-0 d-none d-md-block"
          >
            <hr/>
          </div>

          <div class="right-content">
            <router-outlet name="right"></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
