<div class="h-100 w-100 p-2 p-md-4 m-0 d-flex align-items-stretch">
  <div class="flex-fill beleglos-locked-area d-flex justify-content-center align-items-center">
    <div class="beleglos-locked-content">
      <div class="row d-flex justify-content-center p-3">
        <zlng-icon class="folder-icon" icon="common:folder-dashed" primaryStroke="#9D9D9D"></zlng-icon>
      </div>
      <div class="row d-flex text-center justify-content-center fs-4 p-1">
        Kein Beleg gewählt.
      </div>
      <div class="row d-flex text-center justify-content-center fs-5 p-2">
        Hierzu hast du keinen Beleg ausgewählt, leider ist dies jetzt nicht mehr möglich.
      </div>
    </div>
  </div>
</div>
