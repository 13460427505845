<div class="h-100 w-100 p-2 p-md-4 m-0 d-flex align-items-stretch">
  <div class="flex-fill beleglos-area d-flex justify-content-center align-items-center">
    <div class="beleglos-content">
      <div class="row d-flex justify-content-center p-3">
        <zlng-icon class="folder-icon" icon="common:folder" primaryStroke="#9D9D9D"></zlng-icon>
      </div>
      <div class="row d-flex text-center justify-content-center fs-4 p-1">
        Wähle einen Beleg aus, den du der Zahlung zuordnest.
      </div>
      <div class="row d-flex text-center justify-content-center fs-5 p-2">
        Du kannst die Zahlung auch ohne Beleg tätigen.
      </div>
      <div class="row d-flex justify-content-center p-3">
        <button mat-stroked-button color="accent" class="w-auto" (click)="doOpenBelegChooser()">Beleg auswählen</button>
      </div>
    </div>
  </div>
</div>
